// Swiper Home 

var swiper = new Swiper('.aboutUs__swiper', {
  pagination: {
      el: '.aboutUs__swiper--pagination',
      clickable: true,
  },
  autoplay: {
    delay: 2500,
  },
});

var count = 0;

$('.aboutUs__swiper--pagination .swiper-pagination-bullet').each(function(){
  count++;
  $(this).attr('count', count);
})

$('.swiper-pagination-bullet').click(function(){
  var getCount = $(this).attr('count');
  $('.aboutUs__count--active').text(getCount);
});

var homeSlides = $('.aboutUs__swiper--slide').length;
var homeCurrentSlide = 1;

$('.aboutUs__count--full').text(homeSlides);

swiper.on('slideNextTransitionStart', function () {
  nextSlide();
});

swiper.on('slidePrevTransitionStart', function () {
  previousSlide();
});


function nextSlide() {
  var newHomeCurrentSlide = $('.swiper-pagination-bullet-active').attr('count');
  newHomeCurrentSlide + 1;
  $('.aboutUs .aboutUs__count--active').text(newHomeCurrentSlide);
}

function previousSlide() {
  var newHomeCurrentSlide = $('.swiper-pagination-bullet-active').attr('count');
  newHomeCurrentSlide - 1;
  $('.aboutUs__count--active').text(newHomeCurrentSlide);
}

// Swiper lastRealizations 

var swiperRealizations = new Swiper('.realizationsSwiper', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.realizationsPagination',
    clickable: true,
  },
  autoplay: {
    delay: 2500,
  },
});

countLast = 0

var homeSlidesLast = $('.lastRealizationContent .swiper-slide').length;

$('.lastRealizationContent .aboutUs__count--full').text(homeSlidesLast);

$('.realizationsPagination .swiper-pagination-bullet').each(function(){
  countLast++;
  $(this).attr('count', countLast);
})

$('.realizationsPagination .swiper-pagination-bullet').click(function(){
  var getCount = $(this).attr('count');
  $('.realizationsPagination .aboutUs__count--active').text(getCount);
});

swiperRealizations.on('slideNextTransitionStart', function () {
  nextSlideLast();
});

swiperRealizations.on('slidePrevTransitionStart', function () {
  previousSlideLast();
});

function nextSlideLast() {
  var newHomeCurrentSlide = $('.lastRealizationContent .swiper-pagination-bullet-active').attr('count');
  newHomeCurrentSlide + 1;
  $('.lastRealizationContent .aboutUs__count--active').text(newHomeCurrentSlide);
}

function previousSlideLast() {
  var newHomeCurrentSlide = $('.lastRealizationContent .swiper-pagination-bullet-active').attr('count');
  newHomeCurrentSlide - 1;
  $('.lastRealizationContent .aboutUs__count--active').text(newHomeCurrentSlide);
}