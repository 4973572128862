$('#nav-icon1').click(function(){
  $(this).toggleClass('open');
  $('.mainMenu').slideToggle();
});

$("#scroll").click(function(){
  $('html, body').animate({
    scrollTop: $( '#toScroll' ).offset().top -50
  }, 1000);
});

// portfolio

$('.portfolioList li').click(function(){
  $(this).addClass('menu__element--active').siblings().removeClass('menu__element--active');
});

