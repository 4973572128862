
$( document ).ready(function() {

	$("#mainForm").validate({
		rules: {
			email: {
				required: true,
				email: true
			},
			name: {
				required: true
			},
			surname: {
				required: true
			},
			phone :{
				required: true
			},
			message :{
				required: true
			},
		},
		messages: {
			name: {
				required: "Proszę wpisać imię"
			},
			surname: {
				required: "Proszę wpisać nazwisko"
			},
			phone: {
				required: "Podaj numer kontaktowy"
			},
			message: {
				required: "Proszę podać treść wiadomości"
			},
			email: {
				required: "Proszę wpisać e-mail kontaktowy",
				email: "Proszę wpisać poprawny email"
			},
		}

	});
});